@use "./config/" as *;
@forward "./main-scss/";
@forward "./listing/common";
.waf-listing {
    --_listing-line: 2;
    --_listing-title: 14;
    @include listing-card(vertical);
    .article {
        &-thumbnail {
            @extend %relative;
        }
        &-item {
            --_progress-size: 6rem;
            @extend %mb-4;
        }
        &-content {
            background: transparent;
            color: var(--neutral-800);
            margin: 0;
            & > a {
                order: -1;
            }
        }
        &-info {
            @extend %mt-auto;
            @extend %mb-1;
            @extend %pr-2;
            p {
                @extend %font-12-pm;
                &::after {
                    content: "|";
                    @extend %pl-2;
                }
            }
        }
        &-meta {
            width: calc(100% - 10rem)
        }
    }
}
@include mq(col-md) {
    .waf-listing {
        --_listing-line: 2;
        --_listing-title: 16;
        @include listing-card();
        .article {
            &-content {
                margin: 0;
                padding: var(--space-2) 0;
                color: var(--neutral-800)
            }
        }
    }
}